<template>
    <div class="changeLease">
        <!--        导航-->
        <div>
            <van-nav-bar title="变更合同" left-arrow @click-left="leftReturn" />
        </div>
        <!--        租约详情-->
        <div class="leaseDetail">
            <img v-if="changeOfList.path" class="leaseDetailImg" :src="changeOfList.path" />
            <div v-else class="leaseDetailNullImg"></div>
            <div class="leaseDetailText">

                <p class="addressInfo">{{changeOfList.houseAddress}}</p>

                <div class="detailInfo">
                    <p class="pTwo">{{changeOfList.contractBeginTime}}至{{changeOfList.contractEndTime}}</p>
                    <p class="pTwo">{{changeOfList.rentMoney}}/月  (押一)</p>
                    <p class="pTwo">{{changeOfList.payTypeName}}</p>
                </div>
            </div>
        </div>
        <!--        变更类型-->
        <div class="changeType">
            <div :class="!leaseType? 'grayDot' : 'redDot'"></div>
            <div class="pOne">变更类型</div>
            <div class="ver">|</div>
            <!--            <div>-->
            <!--                <select v-model="selected" @change="changeSelect($event)">-->
            <!--                    <option :value="i" v-for="(item,i) in slist" :key="i">{{item}}</option>-->
            <!--                </select>-->
            <!--            </div>-->

            <span class="typesShow" @click="showLeaseChange">{{leaseType?leaseType:'请选择变更类型'}}</span>
            <img src="../../../assets/images/triangle.png" :class="!isShowTitle?'downImage':'upImage'">
        </div>
        <van-popup v-model="isShowTitle" position="bottom">
            <van-picker show-toolbar :columns="slist"
                        @cancel="isShowTitle = false" @confirm="selectType" />
        </van-popup>
        <!--        离房原因-->
        <div class="changeType">
            <div :class="date=='' ? 'grayDot' : 'redDot'"></div>
            <div class="pOne">离房时间</div>
            <div class="ver">|</div>
<!--            <input class="vanCell" v-model="date" placeholder="预计离开时间" @click="show = true"/>-->
            <span class="vanCell" @click="show = true">{{date}}</span>
            <img class="changeTypeImg" src="../../../assets/images/calendar.png" @click="show = true"/>
<!--            <van-calendar v-model="show"  @confirm="onConfirm"/>-->
        </div>

        <van-popup v-model="show" position="bottom" class="selectTime">
            <div class="date-dropDownList" >
                <van-datetime-picker type="date" title="选择年月日" item-height="35px" @confirm="onConfirm"/>
            </div>
        </van-popup>

        <!--        变更原因-->
        <div class="changeCause">
            <div class="textareaTitle">
                <div :class="text=='' ? 'grayDot' : 'redDot'"></div>
                <div class="pOne">变更原因</div>
            </div>
            <textarea class="textareaInput" v-model="text"></textarea>
        </div>

        <div v-if="changeType==3">
            <div class="changeRenter">转租信息</div>
            <div class="changeType">
                <div :class="!newRenterPhone? 'grayDot' : 'redDot'"></div>
                <div class="pOne">租客电话</div>
                <div class="ver">|</div>
                <span class="typesShow" @click="goToChoosePhone">{{newRenterPhone?newRenterPhone:'请输入电话号码'}}</span>
                <img :src="defaultImage" class="imageTo">
            </div>
            <div>
<!--                <van-popup v-model="isMobileShow" position="bottom">-->
<!--                    &lt;!&ndash;搜索栏&ndash;&gt;-->
<!--                    <div class="searchDiv">-->
<!--                        <van-search  placeholder="请输入" v-model="phoneSearch" size="large" @input="quaryPhoneList()"/>-->
<!--                    </div>-->
<!--                    <van-picker show-toolbar value-key="mobile" :columns="phoneArr"-->
<!--                                @cancel="isMobileShow = false"-->
<!--                                @confirm="selectPhone" />-->
<!--                </van-popup>-->
            </div>
            <div class="changeType">
                <div class="pOne-1">姓名</div>
                <div class="ver">|</div>
                <span class="typesShow">{{newRenterName}}</span>
            </div>
            <div class="changeType">
                <div class="pOne-1">证件号</div>
                <div class="ver">|</div>
                <span class="typesShow">{{newRenterCeType}}</span>
            </div>
            <div class="changeType">
                <div class="pOne-1">转租原因</div>
                <div class="ver">|</div>
                <span class="typesShow" @click="isReasonShow=true">{{changeLeaseReason?changeLeaseReason:'请选择原因'}}</span>
                <img src="../../../assets/images/triangle.png" :class="!isReasonShow?'downImage':'upImage'">
            </div>
            <div>
                <van-popup v-model="isReasonShow" position="bottom">
                    <van-picker show-toolbar value-key="dictionaryTitle" :columns="reasonArr"
                                @cancel="isReasonShow = false"
                                @confirm="selectReason" />
                </van-popup>
            </div>
            <div class="changeType">
                <div :class="!changeLeaseTime ? 'grayDot' : 'redDot'"></div>
                <div class="pOne">房屋转出时间</div>
                <div class="ver">|</div>
                <input class="vanCell-1" v-model="changeLeaseTime" placeholder="预计转出时间" @click="showLeaseTime = true"/>
                <img class="changeTypeImg" src="../../../assets/images/calendar.png" @click="showLeaseTime = true"/>
                <van-calendar v-model="showLeaseTime"  @confirm="onConfirmLeaseTime"/>
            </div>
        </div>


        <div v-if="changeType==4">
            <div class="changeRenter">房屋信息</div>
            <div class="changeType">
                <div :class="!changeRoomAddress? 'grayDot' : 'redDot'"></div>
                <div class="pOne">房屋地址</div>
                <div class="ver">|</div>
                <span class="typesShow" @click="goToChooseRoomList">{{changeRoomAddress?changeRoomAddress:'请选择地址'}}</span>
                <img :src="defaultImage" class="imageTo">
            </div>
            <div>
<!--                <van-popup v-model="isRoomShow" position="bottom">-->
<!--                    &lt;!&ndash;搜索栏&ndash;&gt;-->
<!--                    <div class="searchDiv">-->
<!--                        <van-search  placeholder="请输入" v-model="roomSearch" size="large" @input="quaryRoomList()"/>-->
<!--                    </div>-->
<!--                    <van-picker show-toolbar value-key="roomDetailedAddress" :columns="roomList"-->
<!--                                @cancel="isRoomShow = false"-->
<!--                                @confirm="selectRoom" />-->
<!--                </van-popup>-->
            </div>
        </div>


        <button @click="submit" :class="!canSave() ? 'btnGray' : 'btnOrange'"
                :disabled="!canSave()">提交</button>
    </div>
</template>

<script>
    import {NavBar, Calendar, cell, Picker, Popup, search, DatetimePicker} from 'vant';
    import {changeOfLeaseInit,queryBaseData,userLeaseChangeSave,remoteMobileSearch,findUserInfoByMobile,getRenterRoomHouseList,getStaffFuntionModelList} from "../../../getData/getData";
    import {globaluserId, responseUtil,getStaffId,checkAuthList} from "../../../libs/rongxunUtil";
    import eventBus from "../../../components/rongxun/rx-navBar/envbus";
    export default {
        components: {
            [NavBar .name]:NavBar,
            [Calendar .name]:Calendar,
            [cell .name]:cell,
            [Picker.name]: Picker,
            [Popup.name]: Popup,
            [search.name]:search,
            [DatetimePicker.name]: DatetimePicker,
        },
        data() {
            return {
                isShowTitle:false,
                leaseType:'',
                changeOfList:[],
                fdName: ["CONTRACTHISTORYHISTORYTYPEMAP"],
                dbName: ["subletReason"],
                typeArr:[],
                defaultImage:require('../../../assets/images/youjiantou.png'),
                slist: [],
                text : '',
                date : '',
                show : false,
                selected : -1,
                changeType:'',
                type:'',// 1租客 2业主
                id:'',//间（套）房源id（间roomHouse_id/套setHouse_id）
                contractId:'',
                mode:'',//mode 0我的 1工作台
                ownerOrRenterContractId:'',
                mobile:'',
                houseAddress:'',
                estateRoom_id:'',
                contractCode:'',//合同编号
                potentialResource_id:'',
                //转租信息  换房信息
                //选择电话
                newRenterPhone:'',
                isMobileShow:false,
                phoneSearch:'',
                phoneArr:[],
                newRenterName:'',
                newRenterCeType:'',
                //转租原因
                isReasonShow:false,
                changeLeaseReason:'',
                reasonArr:[],
                //时间
                showLeaseTime:false,
                changeLeaseTime:'',
                //房屋地址
                changeRoomAddress:'',
                isRoomShow:false,
                roomSearch:'',
                roomList:[],
                changeRoomId:'',//换房的新房源id
                authOwnerButtonsList:[],//业主合同按钮权限列表
                authRenterButtonsList:[]//租客合同按钮权限列表
            }
        },
        mounted() {
            this.type = this.$route.query.type
            this.id = this.$route.query.id
			// console.log(this.id+"id")
            this.contractId = this.$route.query.contractId
            this.mode = this.$route.query.mode
            this.ownerOrRenterContractId = this.$route.query.ownerOrRenterContractId
            this.mobile = this.$route.query.mobile
            this.houseAddress = this.$route.query.houseAddress
            this.estateRoom_id = this.$route.query.estateRoom_id
            this.contractCode = this.$route.query.contractCode
            this.potentialResource_id = this.$route.query.potentialResource_id
            this.initData()
            this.getQueryBaseData()
            this.quaryPhoneList()
            this.quaryRoomList()
            this.getOwnerStaffFuntionModelList()
            this.getRenterStaffFuntionModelList()
        },

        activated() {
            eventBus.$on('selectRenterMobileList', function(data){
                if(data.mobile){
                    this.newRenterPhone = data.mobile
                    this.findUserInfoByMobile(this.newRenterPhone)
                }
            }.bind(this));
            eventBus.$on('selectContractRoomList', function(data){
                if(data.roomInfo){
                    this.changeRoomAddress = data.roomInfo.roomDetailedAddress
                    this.changeRoomId = data.roomInfo.id
                }
            }.bind(this));
        },

        //如果前往的页面不是推荐人，则摧毁缓存
        beforeRouteLeave:function(to, from, next){
            if (from && to.name != 'selectRenterMobileList' && to.name != 'selectContractRoomList')
            {//此处判断是如果返回上一层，你可以根据自己的业务更改此处的判断逻辑，酌情决定是否摧毁本层缓存。
                if (this.$vnode && this.$vnode.data.keepAlive)
                {
                    if (this.$vnode.parent && this.$vnode.parent.componentInstance && this.$vnode.parent.componentInstance.cache)
                    {
                        if (this.$vnode.componentOptions)
                        {
                            var key = this.$vnode.key == null
                                ? this.$vnode.componentOptions.Ctor.cid + (this.$vnode.componentOptions.tag ? `::${this.$vnode.componentOptions.tag}` : '')
                                : this.$vnode.key;
                            var cache = this.$vnode.parent.componentInstance.cache;
                            var keys  = this.$vnode.parent.componentInstance.keys;
                            if (cache[key])
                            {
                                if (keys.length) {
                                    var index = keys.indexOf(key);
                                    if (index > -1) {
                                        keys.splice(index, 1);
                                    }
                                }
                                delete cache[key];
                            }
                        }
                    }
                }
                this.$destroy();
            }
            next();
        },

        methods :{

            //获取业主合同权限方法
            getOwnerStaffFuntionModelList () {
                var that = this
                let data = {}
                data.staff_id = getStaffId()
                data.menuName = 'ownerContractList_index'
                getStaffFuntionModelList(data).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.authOwnerButtonsList = response.data.data.data
                    })
                })
            },

            //获取租客合同权限方法
            getRenterStaffFuntionModelList () {
                var that = this
                let data = {}
                data.staff_id = getStaffId()
                data.menuName = 'renterContract_index'
                getStaffFuntionModelList(data).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.authRenterButtonsList = response.data.data.data
                    })
                })
            },

            checkAuthList,

            showLeaseChange(){
                let that = this
                //根据权限动态改变数组
                let list = []
                list.push('退房');
                // if(that.type==1){
                //     if(checkAuthList(that.authRenterButtonsList,'renewal')){
                //         list.push('续签')
                //     }
                //     if(checkAuthList(that.authRenterButtonsList,'terminate')){
                //         list.push('退房')
                //     }
                //     if(checkAuthList(that.authRenterButtonsList,'toSublet')){
                //         list.push('转租')
                //     }
                //     if(checkAuthList(that.authRenterButtonsList,'exchangeHouses')){
                //         list.push('换房')
                //     }
                // } else {
                //     if(checkAuthList(that.authOwnerButtonsList,'renewal')){
                //         list.push('续签')
                //     }
                //     if(checkAuthList(that.authOwnerButtonsList,'terminate')){
                //         list.push('退房')
                //     }
                // }
                that.slist = list
                that.isShowTitle = true
            },


            selectType(item){
                console.log(item)
                this.leaseType = item
                if(item=='续签'){
                    this.changeType = 1
                } else if(item=='退房'){
                    this.changeType = 2
                } else if(item=='转租'){
                    this.changeType = 3
                } else if(item=='换房'){
                    this.changeType = 4
                }
                this.isShowTitle = false
            },

            leftReturn(){
                this.$router.go(-1)
            },
            // 变更租约初始化
            initData(){
                var that = this
                let initData = {}
                // 接收参数
                initData.type = that.type// 1租客 2业主
                initData.id =  that.id //间（套）房源id（间roomHouse_id/套setHouse_id）
                initData.ownerOrRenterContractId = that.ownerOrRenterContractId
                initData.user_id=globaluserId()
                //initData.user_id=44
                changeOfLeaseInit(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.changeOfList = response.data.data
                        //默认离房时间为合同的结束时间
                        that.date = response.data.data.contractEndTime
                        that.changeLeaseTime = that.getNewDataDays(that.date,1)
                    })
                })
            },
            // 字典表接口
            getQueryBaseData(){
                var that = this
                let queryBD = {}
                // 接收参数
                queryBD.fdName=that.fdName
                queryBD.dbName=that.dbName
                //console.log(queryBD)
                queryBaseData(queryBD).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.typeArr = response.data.data.CONTRACTHISTORYHISTORYTYPEMAP
                        that.reasonArr = response.data.data.subletReason
                        let type = that.type// 1租客 2业主
                        if(type=='2')
                            that.typeArr = that.typeArr.slice(0,2)
                        for (let index = 0; index < that.typeArr.length; index++) {
                            that.slist.push(that.typeArr[index].dictionaryTitle)
                        }
                    })
                })
            },
            formatDate(date){
                var year = date.getFullYear();
                var month = date.getMonth() + 1;
                if (month < 10) month = "0" + month;
                var days = date.getDate();
                if (days < 10) days = "0" + days;
                return year + "-" + month + "-" + days;
            },
            onConfirm(value){
                let that = this
                that.show=false
                // this.date=this.formatDate(date)
                var date = new Date(value)
                // this.date = date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()
                that.date = that.formatDate(date)
                this.changeLeaseTime = this.getNewDataDays(this.date,1)
            },
            // checkInDateConfirm(value){
            //     this.toTime = value
            //     var date = new Date(value)
            //     this.checkInDate = date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()
            //     this.isCheckInDateShow = false
            // },
            changeSelect(event){
                this.selected=event.target.value
            },

            //进入手机号选择页面
            goToChoosePhone(){
                this.$router.push('selectRenterMobileList')
            },

            //进入房间选择页面
            goToChooseRoomList(){
                this.$router.push('selectContractRoomList')
            },

            //根据输入的号码模糊查询手机号列表
            quaryPhoneList(){
                let that = this
                let queryData = {}
                queryData.searchInfo = that.phoneSearch
                remoteMobileSearch(queryData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.phoneArr = response.data.data.data
                    })
                })
            },
            //手机号下拉选择
            selectPhone(item){
                this.newRenterPhone = item.mobile
                this.findUserInfoByMobile(this.newRenterPhone)
                this.isMobileShow = false
            },
            findUserInfoByMobile(mobile){
                let that = this
                let initData = {}
                initData.userMobile = mobile
                findUserInfoByMobile(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        let result = response.data.data.data
                        if (result && result.userName) {
                            that.newRenterName = result.userName
                            that.newRenterCeType =  result.certificateCode || ''
                        }
                    })
                })
            },
            //选择转租原因
            selectReason(item){
                this.changeLeaseReason = item.dictionaryTitle
                this.isReasonShow = false
            },
            //转租时间
            onConfirmLeaseTime(date){
                this.showLeaseTime = false
                this.changeLeaseTime = this.formatDate(date)
            },
            getNewDataDays(dateTemp, days) {
                var arr = dateTemp.split("-");
                var nDate = new Date(arr[1] + "/" + arr[2] + "/" + arr[0]); //转换为MM-DD-YYYY格式
                var millSeconds = Math.abs(nDate) + days * 24 * 60 * 60 * 1000;
                var rDate = new Date(millSeconds);
                var year = rDate.getFullYear();
                var month = rDate.getMonth() + 1;
                if (month < 10) month = "0" + month;
                var date = rDate.getDate();
                if (date < 10) date = "0" + date;
                return year + "-" + month + "-" + date;
            },
            //房源列表模糊查询
            quaryRoomList(){//getRoomHouseList
                let that = this
                let initData = {}
                initData.staff_id = getStaffId()
                initData.searchInfo = that.roomSearch
                getRenterRoomHouseList(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        let result = response.data.data.data
                        that.roomList = result
                    })
                })
            },
            //具体房间选择查询
            selectRoom(item){
                this.changeRoomAddress = item.roomDetailedAddress
                this.changeRoomId = item.id
                this.isRoomShow = false
            },

            //检验可不可以提交的方法
            canSave(){
                let that = this
                if(that.type==1){
                    //租客的租约变更
                    if(that.leaseType&&that.date&&that.text&&(that.changeType==1 || that.changeType==2 ||
                        (that.changeType==3&&that.newRenterPhone&&that.changeLeaseTime) ||
                        (that.changeType==4&&that.changeRoomAddress))){
                        return true
                    }
                } else {
                    //业主的租约变更
                    if(that.leaseType&&that.date&&that.text){
                        return true
                    }
                }
                return false
            },

            //提交申请
            submit(){
                let that = this;
                let sbm = {}
                sbm.user_id= globaluserId()
                sbm.roomOrHouse_id=this.id
                sbm.type=this.type // 2业主  1租户
                //sbm.changeType=parseInt(that.selected)+1 //变更类型 1退租 2续租
                sbm.changeType=that.changeType
                sbm.changeDate= that.date //日期
                sbm.changeReason= that.text //变更原因
                sbm.cId = this.contractId//加一个上一页面传入的合同id
                // 接收参数

                //如果是退租那么调用保存接口,其它情况进入新增页面之后再把填写的数据保存到历史记录里
                if(that.mode==0 || (that.mode==1&&sbm.changeType==2)){
                    userLeaseChangeSave(sbm).then(function (response) {
                        responseUtil.dealResponse(that, response, () => {
                            responseUtil.alertMsg(that,'操作成功')
                            // let contracthistoryId = response.data.data.contractHistoryId
                            //重定向到列表页面 //mode 0我的 1工作台
                            if(that.mode=='0'){
                                that.$router.push("myContract")
                            }else if(that.mode=='1'&&sbm.type=='2'&&sbm.changeType=='2') {
                                that.$router.push({
                                    name : 'contractList',
                                    query : {
                                        currentLabel: '1',
                                        type:'2'
                                    }
                                })
                            } else if(that.mode=='1'&&sbm.type=='1'&&sbm.changeType=='2'){
                                that.$router.push({
                                    name : 'contractList',
                                    query : {
                                        currentLabel: '1',
                                        type:'1'
                                    }
                                })
                            }
                        })

                    })
                } else {
                    if(that.mode=='1'&&sbm.type=='2'&&sbm.changeType==1){
                        //业主合同续签 新增业主合同
                        let changeInfoParam = {
                            ownercontract_id:that.ownerOrRenterContractId,
                            normalType:'1',
                            hasResponsibility:'0',
                            addStaff_id:getStaffId(),
                            changeType:that.changeType,
                            leaveTime:that.date,
                            remarks:that.text
                        }
                        that.$router.push({
                            name: 'newOwnerContract',
                            params:{
                                flagType:'2',
                                ownerContractId:that.ownerOrRenterContractId,
                                mobile:that.mobile,
                                houseAddress:that.houseAddress,
                                estateRoom_id:that.estateRoom_id,
                                contractCode:that.contractCode,
                                prId:that.potentialResource_id,
                                changeInfoParam:changeInfoParam
                            }
                        })
                    } else if (that.mode=='1'&&sbm.type=='1'&&sbm.changeType!=2){
                        //除了退租以外都要跳转到新增租客合同页面
                        //新增分三种情况 续签 转租 换房
                        let changeInfoParam = {
                            rentercontract_id:that.ownerOrRenterContractId,
                            normalType:'1',
                            hasResponsibility:'0',
                            addStaff_id:getStaffId(),
                            changeType:that.changeType,
                            leaveTime:that.date,
                            remarks:that.text
                        }
                        //1.续签  (原房间信息不变,租客信息不变)
                        if(sbm.changeType==1){
                            that.$router.push({
                                name: 'NewContract',
                                query:{
                                    flagType:'2',//2代表续签 3代表转租 4代表换房
                                    contractId:that.ownerOrRenterContractId,
                                    newStartDate:that.getNewDataDays(that.date,1),
                                    changeInfoParam:changeInfoParam,
                                    roomId:that.id     //赵
                                }
                            })
                        }
                        //2.转租  (房间信息不变,租客信息变化，需要往新增页面传入新的租客信息)
                        if(sbm.changeType==3){
                            that.$router.push({
                                name: 'NewContract',
                                query:{
                                    flagType:'3',//2代表续签 3代表转租 4代表换房
                                    contractId:that.ownerOrRenterContractId,
                                    roomId:that.id,
                                    mobile:that.mobile,
                                    newRenterMobile:that.newRenterPhone,
                                    newRoomId:that.changeRoomId,
                                    newStartDate:that.getNewDataDays(that.date,1),
                                    houseAddress:that.houseAddress,
                                    changeInfoParam:changeInfoParam
                                }
                            })
                        }
                        //3.换房  (房间信息变化,需要重新选择房间,租客信息不变)
                        if(sbm.changeType==4){
                            that.$router.push({
                                name: 'NewContract',
                                query:{
                                    flagType:'4',//2代表续签 3代表转租 4代表换房
                                    contractId:that.ownerOrRenterContractId,
                                    mobile:that.mobile,
                                    newRoomId:that.changeRoomId,
                                    newStartDate:that.getNewDataDays(that.date,1),
                                    houseAddress:that.changeRoomAddress,
                                    changeInfoParam:changeInfoParam,
                                    roomId:that.id     //赵
                                }
                            })
                        }
                    }
                }
            }
        }
    }
</script>

<style lang="less" scoped >
    .changeLease{
        overflow: hidden;
        width: 100%;
    }
    .typesShow{
        font-size: 15px;
        width: 200px;
        color: #999999;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .downImage{
        width: 12px;
        height: 12px;
        margin-right: 10px;
    }
    .upImage{
        width: 12px;
        height: 12px;
        margin-right: 10px;
        transform: rotateX(180deg);
    }

    *{
        margin: 0;
        padding: 0;
    }
    .pOne{
        font-size: 15px;
        font-weight: bold;
        margin-left: 10px;
    }
    .pOne-1{
        font-size: 15px;
        font-weight: bold;
        margin-left: 31px;
    }
    .pTwo{
        font-size:12px ;
        color: #999999;
    }
    //租约详情
    .leaseDetail{
        width: 92%;
        margin: 15px auto 0px;
        background-color: #FFFFFF;
        border-radius: 8px;
        height: 108px;
        display: flex;
        .leaseDetailImg{
            width: 100px;
            height: 75px;
            margin: 15px 10px 18px;
            border-radius: 8px;
        }
        .leaseDetailNullImg{
            width: 100px;
            height: 75px;
            margin: 15px 10px 18px;
            background-color: #D8D8D8;
            border-radius: 8px;
        }
        .leaseDetailText{
            margin-top: 15px;
            .addressInfo{
                font-size: 15px;
                font-weight: bold;
                height: 21px;
                line-height: 21px;
                width: 205px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
            .detailInfo{
                height: 51px;
                line-height: 17px;
                margin-top: 5px;
            }
        }
    }
    .grayDot{
        border: 3px solid #999999;
        border-radius: 50%;
        margin-left:15px;
    }
    .redDot{
        border: 3px solid #FF5D3B;
        border-radius: 50%;
        margin-left:15px;
    }
    .ver{
        margin-left: 10px;
        margin-right: 10px;
        font-size: 15px;
        color:#999999 ;
    }
    .changeType {
        width: 92%;
        height: 50px;
        line-height: 40px;
        display: flex;
        border-radius: 8px;
        background-color: #FFFFFF;
        margin: 15px auto 0;
        align-items: center;
        .changeTypeImg{
            width: 25px;
            height: 25px;
        }
        .vanCell{
            /*height: 40px;*/
            /*line-height: 40px;*/
            width: 56%;
            border: none;
            font-size: 15px;
            color: #FF5D3B;
        }
        .vanCell-1{
            /*height: 40px;*/
            /*line-height: 40px;*/
            width: 47%;
            border: none;
            font-size: 15px;
            color: #FF5D3B;
        }
        select{
            width: 215px;
            height: 40px;
            border: none;
            background-color: #FFFFFF;
            margin-bottom: 5px;
        }
    }
    .changeCause{
        width: 92%;
        height: 185px;
        margin: 27px auto;
        background-color: #FFFFFF;
        border-radius: 8px;
        .textareaTitle{
            display: flex;
            height: 45px;
            line-height: 45px;
            align-items: center;
        }
        .textareaInput{
            display: block;
            width: 84%;
            margin: -3px auto 20px;
            height: 90px;
            border-radius: 8px;
            background-color: #f7f7f7;
            border: none;
            resize: none;
            padding: 15px;
            font-size: 14px;
        }
    }
    .btnGray,.btnOrange{
        display: block;
        width: 92%;
        height: 45px;
        color: #FFFFFF;
        border-radius: 8px;
        font-size: 16px;
        margin: 44px auto 35px;
        border: none;
    }
    .btnGray{
        background-color:#B8B8B8  ;
    }
    .btnOrange{
        background-image: linear-gradient(to right,#FFC274,#FF5D3B);
    }
    .changeRenter{
        font-size: 15px;
        font-weight: bold;
        margin-top: 15px;
        margin-left: 15px;
    }
    .imageTo{
        width: 9px;
        height: 16px;
    }
</style>
